@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap");

.App {
  min-height: 100vh;
  display: flex;
  background-image: url("./background.png");
  background-position: center;
  background-size: cover;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.281);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
